import React from 'react';
import {
    Button, Input, InputGroup,
    InputGroupAddon

} from 'reactstrap';
// import Garantee from './Garantee'
function Footer() {
    return (
        <div>
        <div className="footer-spacer">
             {/*<Garantee/>*/}
        <div className="footer">
            <div className="middle-footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row  mt-4">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <span className="footer-link-text">CONTACT INFORMATION</span>
                                    <ul className="list-unstyled info" style={{ lineHeight: '5vh' }}>
                                        <li className="number"><div className="footer-link-text-div">+44 7456 094702
                                        </div><div className="footer-div">(10am to 6pm)</div></li>
                                        <li className="support-mail">
                                            <div className="footer-link-text-div">
                                            support@cmct-fashion.com
                                            </div>
                                            <div className="footer-div">Response within 6hrs</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 footer-div">
                                    <ul className="list-unstyled">
                                        <span className="footer-link-text">OVERVIEW</span>
                                        <li className="footer-link-text-div">Privacy Policy</li>
                                        <li className="footer-link-text-div">Terms & Conditions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="third-footer-section" style={{display: "None"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h6>Join Our Newsletter Now</h6>
                            <p>Get E-mail updates about out latest shop and special offers.</p>
                        </div>
                        <div className="col-lg-4">

                            <InputGroup>
                                <Input type="text" className="footer-input" placeholder="Enter your mail" />
                                <InputGroupAddon addonType="prepend"><Button id="btn-addon">SUBSCRIBE</Button></InputGroupAddon>
                            </InputGroup>


                        </div>
                    </div>
                </div>
            </div>
            <div className="fourth-footer-section" style={{display: "None"}}>
            </div>
        </div>
    </div>
        </div>
    )
}

export default Footer;