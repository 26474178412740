import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem, Row, Col, Button, Input, FormGroup, InputGroup, InputGroupText, InputGroupAddon
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { withCookies } from "react-cookie";
import { filterAction } from "../store/actions/filterCollapseAction";
import {authAction, vehicleAction, searchAction, cartAction} from "../store/actions";
import SlidingPane from "react-sliding-pane";
import IconButton from '@material-ui/core/IconButton';
import credentials from "../config/credentials";
import cmcmtLogo from "../assets/images/cmct/cmct-logo-traced.png";
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { Badge } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import Tab from "react-bootstrap/Tab";


class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnCatSearch = this.handleOnCatSearch.bind(this);
    this.handleSidePane = this.handleSidePane.bind(this);
    this.handleDeleteCartItem = this.handleDeleteCartItem.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this)
    const { cookies } = props;

    this.state = {
      isOpen: false,
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      redirect: false,
      searchInput: "",
      allProductList: [],
      isSidePaneOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }
  }

  componentDidMount() {
    const { getAllProducts } = this.props;
    getAllProducts('all');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.vehicleReducer.getAllProductsSuccess &&
      this.props.vehicleReducer.getAllProductsSuccess !== prevProps.vehicleReducer.getAllProductsSuccess){
      this.setState({allProductList: this.props.vehicleReducer.getAllProductsSuccess.results})
    }
    if (this.props.searchReducer.filterSearch &&
        this.props.searchReducer.filterSearch !== prevProps.searchReducer.filterSearch){
      this.props.history.push({
        pathname: `/search/${this.state.searchInput}`,
        state: { detail: this.props.searchReducer.filterSearch }
      })
    }
  }

  handleSidePane = (e) => {
    const isOpen = this.state.isOpen
    if (e) {
      if (isOpen) {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      this.props.history.push({
        pathname: "/my-basket"
      })
    }
    else {
      this.setState({isSidePaneOpen:  true })
      if (this.state.isOpen){
        this.toggle()
      }
    }
  }

  toggle() {
    this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleInputChange(event) {
    const {value, name} = event.target;
    this.setState({ searchInput: value });
  }

  handleDeleteCartItem (prodId, prodOpt) {
    this.props.deleteCartProduct({prodId: prodId, prodOpt: prodOpt})
  }

  handleCheckout () {
    const { cartProducts } = this.props.cartReducer
    if (cartProducts.length > 0) {
      this.setState({
        isSidePaneOpen: false
      })
      this.props.history.push('/login')
    } else {
      alert('Add items to your bag before checkout!')
      return;
    }
  }

  handleOnSearch(value) {
    const { searchInput } = this.state;
    if (searchInput === "") {
      alert("Search keyword missing");
      return;
    } else {
      this.props.filterByValue({value: searchInput, defaultList: this.state.allProductList})
    }
  }

  handleOnCatSearch(cat) {
    this.props.filterByCat({cat: cat, defaultList: this.state.allProductList})
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
      this.toggleNav();
    }
  }

  handleSignOut = async event => {
  const { cookies } = this.props;
  const token = localStorage.getItem('token')
  event.preventDefault();
  if (this.props.apiTokenLogout) {
    await this.props.apiTokenLogout({
      cookies,
      sessionToken: {
        token: token
      }
  });

  this.setState({
    userLoggedIn: false,
    email: "",
    redirect: true
  })

  this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  this.props.history.push('/')
  }
  }


  render() {
    const { isLoggedIn } = this.props.authReducer
    let cartTotal = 0
    let basketSum = 0
    let reducedCart = []

    const { cartProducts } = this.props.cartReducer
    if (cartProducts.length > 0) {
      cartTotal = cartProducts.length
      basketSum = cartProducts.reduce((acc, basketItem) => {
        return acc + parseFloat(basketItem.prodPrice)
      }, 0);
      reducedCart = Object.values(cartProducts.reduce((a, b) => {
        a[b.prodId + b.prodOpt.name] = Object
          .assign(b, {
            count: (
              a[b.prodId + b.prodOpt.name] || {count: 0}).count + 1}
              );
        return a;
      }, {}));
    }


    return (
      <div className="header-section">
        <div className="header-container">
          <span/>
          <div className="header-left">
            {/*<span className="logo-left" style={{fontWeight: '500'}}>exUK</span>*/}
          </div>
          <div className="header-right">
            <Navbar dark expand="lg" id="" className="upper-nav">
              <Nav
                className="ml-auto"
                style={{ display: "inline-block" }}
                navbar>
              </Nav>
            </Navbar>
            <Navbar dark expand="lg" id="navbar-first-row">
              <NavbarBrand href="/" className="mr-auto" style={{paddingLeft: '5px'}}>
                <span className="logo-right">

                  CMCT
                  <img style={{height: '25px', padding: '0 10px'}}
                       src={cmcmtLogo} alt="" />
                  FASHION
                </span>
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Nav className="ml-auto" id="navbar-nav" navbar>
                <NavItem>
                  <Link
                  to="/my-basket">
                  </Link>

                </NavItem>
                <div>&nbsp;</div>
                <NavItem>
                  {!isLoggedIn && (
                    <Link
                      to="/login"
                      onClick={this.closeNavbar}
                      className="fancy">
                      <span className="fancy-header-items"> Sign Up</span>
                    </Link>
                  )}
                  {isLoggedIn && (
                    <Link
                    to="/"
                    onClick={this.handleSignOut}
                    className="fancy">
                    <span className="fancy-header-items"> Hi, Sign Out</span>
                    </Link>
                  )}
                </NavItem>
                <div>&nbsp;</div>
                <NavItem className="my-account">
                  <Link
                    to="/profile"
                    className="fancy">
                    <span className="fancy-header-items">Account</span>
                  </Link>{" "}

                </NavItem>
                <div>&nbsp;</div>
                <NavItem className="my-account" style={{padding: "5px 10px 10px 10px"}}>
                  <IconButton color="inherit">
                    <Badge badgeContent={cartTotal} color="secondary">
                      <ShoppingCart
                        style={{fill: "#cbcbcb"}}
                        onClick={() => this.handleSidePane(isLoggedIn)}
                      />
                    </Badge>
                  </IconButton>
                </NavItem>
              </Nav>

            </Navbar>
          </div>
        </div>

        <div className="nav-container">
          <div className="search-bar-container">
            <FormGroup style={{margin: 'auto', maxWidth: '300px'}}>
              <InputGroup style={{border: '2px solid black', borderRadius: '8px'}}>
                <Input
                  style={{padding: '10px', height: '37px', border: '0px'}}
                  name="q"
                  type="text"
                  // value={this.state.userRequest}
                  onChange={this.handleInputChange}
                  placeholder="Search"
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      this.handleOnSearch();
                    }
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <IconButton
                      type="submit"
                      aria-label="search"
                      style={{padding: '0px'}}
                      onClick={this.handleOnSearch}
                    >
                      <SearchIcon
                        style={{fill: "black"}}
                      />
                    </IconButton>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

            </FormGroup>
            {/*<SearchParent/>*/}
          </div>
          <Navbar dark expand="lg" id="navbar-second-row">
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="" navbar>
                <Link
                  to="/"
                  className="mr-3-first-link"
                  onClick={this.closeNavbar}>
                  HOME
                </Link>


                <Link
                  to="/search/cat-search-women"
                  className="mr-3"
                  onClick={() => this.handleOnCatSearch('women')}>
                  WOMEN
                </Link>

                <Link
                  to="/search/cat-search-men"
                  className="mr-3"
                  onClick={() => this.handleOnCatSearch('men')}>
                  MEN
                </Link>

                <Link
                  to="/about-us"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  ABOUT US
                </Link>

                {!isLoggedIn && (
                <Link
                  to="/login"
                  onClick={this.closeNavbar}
                  >
                  <NavItem className="d-block d-sm-block d-md-block d-lg-none hiddenNavItems">
                    Sign in / Register
                  </NavItem>
                </Link>
                )}
                {isLoggedIn && (
                <Link
                  to="/"
                  onClick={this.handleSignOut}
                  >
                  <NavItem className="d-block d-sm-block d-md-block d-lg-none hiddenNavItems">
                    Sign Out
                  </NavItem>
                </Link>
                )}

                <Link
                  to="/profile"
                  onClick={this.closeNavbar}
                >
                  <NavItem className="d-block d-sm-block d-md-block d-lg-none hiddenNavItems">
                    My Account
                  </NavItem>
                </Link>

                {/*<Link*/}
                {/*  to="/my-basket"*/}
                {/*  onClick={this.closeNavbar}*/}
                {/*>*/}
                  <NavItem className="d-block d-sm-block d-md-block d-lg-none hiddenNavItems">
                    <IconButton color="inherit">
                      <Badge badgeContent={cartTotal} color="secondary">
                        <ShoppingCart
                          style={{fill: "black"}}
                          onClick={() => this.handleSidePane(isLoggedIn)}
                        />
                      </Badge>
                    </IconButton>
                  </NavItem>
                {/*</Link>*/}

              </Nav>
            </Collapse>
          </Navbar>
          <SlidingPane
            isOpen={ this.state.isSidePaneOpen }
            from='right'
            width='70%'
            onRequestClose={ () => this.setState({ isSidePaneOpen: false }) }>
            <div>
              <div style={{fontSize: "14px", textAlign: "center", fontWeight: 900, paddingBottom: "10px"}}>
                MY BAG
              </div>
                <div style={{paddingTop: "15px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      {reducedCart && reducedCart.map((d, i) => {
                        return (
                          <div>
                            <Row>
                              <Col sm={9} className="no-padding-cols">
                                <div >
                                  {d.prodTitle} - {' '}
                                    <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>x{d.count}</span>
                                </div>
                                {(Object.getOwnPropertyNames(d.prodOpt).length === 0)
                                  ? null
                                  :
                                  <Row>
                                    <div style={{fontWeight: 'bold'}}>Variant:{' '}{d.prodOpt.name} </div>
                                  </Row>
                                }
                              </Col>
                              <Col sm={3} className="no-padding-cols">
                                <div style={{float: "right", width: "50%", textAlign: "right"}}>
                                  £{d.prodPrice}
                                </div>
                              </Col>
                            </Row>
                            <Row sm={12}>
                              <Col sm={6} className="no-padding-cols">
                                <div>
                                  <IconButton onClick={() => this.handleDeleteCartItem(d.prodId, d.prodOpt.name)}>
                                    <DeleteOutlinedIcon
                                      style={{fill: "black"}}
                                    />
                                  </IconButton>
                                </div>
                              </Col>
                            </Row>
                            <hr/>
                          </div>

                        );
                      })}

                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <div style={{width: "100%"}}>
                        <div style={{float: "left", width: "50%", fontSize: "120%",fontWeight: 'bold'}}>
                          Total
                        </div>
                        <div
                          style={{
                            float: "right",
                            width: "50%",
                            textAlign: "right",
                            fontSize: "120%",
                            fontWeight: 700
                          }}>
                          £{basketSum}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        style={{marginTop: "30px", fontWeight: 300, backgroundColor: "black"}}
                        className="filter-btn"
                        size={"sm"}
                        block
                        onClick={this.handleCheckout}>CHECKOUT
                      </Button>
                    </Col>
                  </Row>
                </div>
            </div>
          </SlidingPane>
        </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  filterReducer: state.filterReducer,
  sideMenuReducer: state.sideMenuReducer,
  authReducer: state.authReducer,
  getShippingMethodsSuccess: state.getShippingMethodsSuccess,
  vehicleReducer: state.vehicleReducer,
  searchReducer: state.searchReducer,
  cartReducer: state.cartReducer

});

const mapDispatchToProps = dispatch => {
  return {
    filterCollapseAction: payload =>
      dispatch(filterAction.setFilterCollapse(payload)),
    apiTokenLogout: payload =>
      dispatch(authAction.apiTokenLogout(payload)),
    getAllProducts: payload =>
      dispatch(vehicleAction.getAllProducts(payload)),
    filterByValue: payload =>
      dispatch(searchAction.filterByValue(payload)),
    filterByCat: payload =>
      dispatch(searchAction.filterByCat(payload)),
    deleteCartProduct: payload =>
      dispatch(cartAction.deleteCartProduct(payload)),
    addProductToBasket: payload =>
      dispatch(vehicleAction.addProductToBasket(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
    withCookies,
    withRouter
)(Header));

