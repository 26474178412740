import React from 'react';
import {Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Loading} from "../LoadingComponent";


class SomeComponent extends React.Component {

  renderBasketData(banana){
    return banana.map(
      ({ title, quantity }, i) => {
        return (
          <div style={{paddingBottom: '12px'}}>
            - {title} <span style={{color: 'gray'}}>x {quantity}</span>
          </div>
        );
      }
    )
  }


  render() {

  const {someProp, onConfirm, toggle, onLogin, onLoading } = this.props

  let message = ""

  if (someProp.modalMessage === "401") {
    message = "You're currently not logged in"
  } else {
    message = "Unable to proceed - Contact customer service, quoting the above error message"
  }

  let arr = Object.values(someProp.basket)
  return <div>

    <div>{this.props.name}</div>
      <div>
         <div>

        <Modal isOpen={someProp.showModal} toggle={toggle.bind(this,false)}>
          <ModalHeader toggle={toggle.bind(this,false)}>{someProp.modalMessage + " Error"}</ModalHeader>
          <ModalBody style={{ textAlign:'center'}}>
            {message}<p></p>
          </ModalBody>
          <ModalFooter>
            {message && message.includes("401") ? <Button color="primary"  onClick={onLogin.bind(this,false)}>Login</Button>
              : <Button color="primary"  onClick={toggle.bind(this,false)}>Okay</Button>
            }

            <Button color="secondary"  onClick={toggle.bind(this,false)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
        <div className="">
          <Card className="bg-light">
            <CardBody style={{color:"#6fa8dd"}} className="text-center">
              <div className="basket-page-information checkout-item-title">DELIVERY DETAILS: </div>
              <div className="basket-page-information basket-page-information-bottom"
                   style={{color: '#000000', fontSize: '13px', fontWeight: 500}}>
                {someProp.userTitle} { someProp.firstName } { someProp.lastName }
                <br />
                { someProp.line1 }
                { someProp.line2 && (
                  <br />
                )}
                {someProp.line2}
                <br />
                { someProp.city }
                <br />
                { someProp.province }
                <br />
                { someProp.userSelectedDestination.name }
              </div>
            </CardBody>
          </Card>
          <hr className="checkout-info-block" />
          <Card className="bg-light">
            <CardBody style={{color:"#6fa8dd"}} className="text-center">
              <div className="basket-page-information checkout-item-title"> YOUR ORDER: </div>
              <div className="basket-page-information" style={{color: '#000000', fontSize: '13px', fontWeight: 500}}>
                {this.renderBasketData(arr)}
                <br />
                <div className="basket-page-information checkout-item-title"> TOTAL: </div>
                <span className="checkout-price"> £{ someProp.totalCost } </span>
                <br />
              </div>
            </CardBody>
          </Card>

          <Card className="bg-light">
            <CardBody style={{color:"#6fa8dd"}} className="text-center">
              <div className="basket-page-information checkout-item-title"> SHIPPING: </div>
              <div className="basket-page-information basket-page-information-bottom"
                   style={{color: '#000000', fontSize: '13px', fontWeight: 500}}>
                { someProp.destinationPort }
                <br />
                <span className="checkout-price" > £{ someProp.shippingChargeOption.value}0</span>
                <br />
              </div>
            </CardBody>
          </Card>
          { onLoading ? (
            <div className="offset-1 offset-lg-1" style={{backgroundColor: '#f8f9fa'}}>
              <Loading />
            </div>
          )
          :
          (
            <div className="col" style={{textAlign: 'center'}}>
              <Button className="delete-basket-button" size="sm" onClick={onConfirm.bind(this,4,false)}>Submit Order</Button>
            </div>
          )}
        </div>
        <br/>
      </div>
  </div>;
  }
}

export default SomeComponent;