export const prodLandingData = [
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CMCT Womens Black Dress",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/cmct-cat-promo-edited.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CMCT Womens Black Dress",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/cmct-cat-promo-edited.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CMCT Womens Black Dress",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/cmct-cat-promo-edited.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CMCT Womens Black Dress",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/cmct-cat-promo-edited.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CMCT Womens Black Dress",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/cmct-cat-promo-edited.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CMCT Womens Black Dress",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/cmct-cat-promo-edited.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  }
  ]