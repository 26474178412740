import {
  SORT_BY_ALPHABET, SORT_BY_PRICE, FILTER_BY_PRICE, FILTER_BY_VALUE,
  FILTER_BY_CAT
} from "../constants";

const initialState = {
  filterSearch: null,
  filterSearchError: null,
  filterSearchLoader: false,
  catSearch: null
};

export default function searchReducer(state = initialState, action) {

  switch (action.type) {

    case SORT_BY_ALPHABET:
      //sort alphabetically
      return state;
    case SORT_BY_PRICE:
      //sort by price
      return state;
    case FILTER_BY_PRICE:
      //filter by price
      return state;

    case FILTER_BY_CAT:
      let cat = action.payload.cat;
      let prods = action.payload.defaultList;
      let filteredProds = prods.filter(product => {
        return product.categories[0].toLowerCase() === cat;
      });
      return {
        ...state,
        catSearch: filteredProds
      }

    case FILTER_BY_VALUE:
      let value = action.payload.value;
      let prodList = action.payload.defaultList
      let filteredValues = prodList.filter(product => {
        return product.title.toLowerCase().includes(value.toLowerCase());
      });
      return {
        ...state,
        filterSearch: filteredValues
      }

    default:
      return state;
  }
}
