import React, {Component} from "react";
import SearchResGrid from "./searchResGrid";
import {searchAction} from "../../store/actions";
import { Loading } from './../LoadingComponent';
import {connect} from "react-redux";
import {compose} from "redux";
import {withCookies} from "react-cookie";
import {withRouter} from "react-router-dom";

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      res: []
    };
  }

  componentDidMount() {
    const query = this.props.match.params.q
    if (!this.props.location.state){
      // fetch from redux
      const {getAllProductsSuccess} = this.props.vehicleReducer;
      if (getAllProductsSuccess) {
        if (query === 'cat-search-men') {
          this.props.filterByCat({cat: "men", defaultList: getAllProductsSuccess.results})
        }
        if (query === 'cat-search-women') {
          this.props.filterByCat({cat: "women", defaultList: getAllProductsSuccess.results})
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchReducer.catSearch &&
      this.props.searchReducer.catSearch !== prevProps.searchReducer.catSearch) {
      this.setState({ res: this.props.searchReducer.catSearch })
    }
    if (this.props.vehicleReducer.getAllProductsSuccess &&
      this.props.vehicleReducer.getAllProductsSuccess !== prevProps.vehicleReducer.getAllProductsSuccess ) {
      const query = this.props.match.params.q

      const {getAllProductsSuccess} = this.props.vehicleReducer;
      if (query === "shop"){
        this.setState({res: getAllProductsSuccess.results})
      }
      if (query === "cat-search-men") {
        this.props.filterByCat({cat: "men", defaultList: getAllProductsSuccess.results})
      }
      if (query === "cat-search-women") {
        this.props.filterByCat({cat: "women", defaultList: getAllProductsSuccess.results})
      }
    }
  }

  render() {
    const {res} = this.state
    const { getAllProductsLoader } = this.props.vehicleReducer
    let searchResults = this.props.location.state && this.props.location.state.detail
    if (!this.props.location.state) {
      searchResults = res
    }

    return (
      <div ref={topElement => (this.topElement = topElement)}>
        <div className="container text-center" id="contactContainer">
          <div className="row" style={{background: 'white'}}>
            <div className="container">
              {getAllProductsLoader
                ?
                <Loading/>
                :
                <div className="row">
                  { searchResults === undefined || searchResults.length === 0 ?
                    <div
                      style={{
                        minHeight: '400px',
                        fontWeight: 800,
                        paddingLeft: '10px'}}>
                      Oops, no exact matches found </div>
                    :
                    searchResults.map((data, index ) => {
                      if (data) {
                        return (
                          <SearchResGrid d={data} i={index}>
                          </SearchResGrid>
                        )
                      }
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  vehicleReducer: state.vehicleReducer,
  searchReducer: state.searchReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    filterByCat: payload =>
      dispatch(searchAction.filterByCat(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
  withCookies,
  withRouter
)(SearchList));



