import {
  ADD_TO_CART,
  DELETE_CART_ITEM,
  MERGE_CART,
  MERGE_CART_SUCCESS,
  MERGE_CART_FAILURE,
  CLEAR_CART
} from "../constants"

const initialState = {
  cartProducts: [],

  mergeCartSuccess: null,
  mergeCartError: null,
  mergeCartLoader: false,
}

export default function cartReducer(state= initialState, action) {
  switch (action.type) {

    case ADD_TO_CART:
      return {
        ...state,
        cartProducts: [...state.cartProducts, action.payload]
      }

    case DELETE_CART_ITEM:
      let result = state.cartProducts.filter(function(v, i) {
        return ((v["prodId"] === action.payload.prodId) && v.prodOpt.name !== action.payload.prodOpt);
      })
      return {
        ...state,
        cartProducts: result
      }

    case CLEAR_CART:
      return {
        ...state,
        cartProducts: []
    }

    case MERGE_CART:
      return {
        ...state,
        mergeCartLoader: true,
        mergeCartSuccess: null,
        mergeCartError: null
      };

    case MERGE_CART_SUCCESS:
      return {
        ...state,
        mergeCartLoader: false,
        mergeCartSuccess: action.payload,
        mergeCartError: null
      }

    case MERGE_CART_FAILURE:
      return {
        ...state,
        mergeCartLoader: false,
        mergeCartSuccess: null,
        mergeCartError: action.error
      }


    default:
      return state
  }
}
