import React, {Component} from 'react';
import Tab from 'react-bootstrap/Tab';
import {Helmet} from "react-helmet";

import {
  Col,
  Row
} from "reactstrap";

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CMCT - About Us</title>
          <link rel="canonical" href="https://www.exukonline.com/about-us" />
          <meta name="description"
                content="UK Premier Import Service - A Global e-commerce company" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#000000'}}>Who we are</h4>
                  <hr className="fancy-line" />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading stock-procurement-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          <p className="how-to-steps" style={{fontSize: '14px'}}>About Us</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                              About us 1
                            </p>
                            <p className="page-information" style={{fontSize: '14px'}}>
                              About us 2.</p>

                          <p className="how-to-steps" style={{fontSize: '14px'}}>Our Approach</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                              About us 3.</p>
                          <p className="how-to-steps" style={{fontSize: '14px'}}>Why Us?</p>
                            About us 4.
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default AboutUs;
