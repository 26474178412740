import React, { Component } from 'react';
import {
  Button, Col
} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import { Loading } from './LoadingComponent';
import './BestDeals.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import ShopByMake from './../containers/ShopByMakeContainer';
import PromotionUpSelling from "./PromotionUpSelling";
import PromotionUpSellingStatic from "./PromotionalUpSellingStatic"
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import {dev_landing_data, homeTechData, homeCommercialData} from "../shared/promo_landing_data"
import PromoElements from "./PromoElements";
import { prodLandingData } from './../shared/prod_promo_landing_data';


class BestDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      isLoading: true,
      errMess: null,
      dealers: [],
      recommended: [],
      parts: [],
      selectedPriceTab: 1,
      selectedSearchedCarTab: 1
    };
  }

  componentDidMount() {
    this.props.getPromotionalApiData && this.props.getPromotionalApiData();

    if (this.props && this.props.location.state && this.props.location.state.pathname && this.props.location.state.pathname === "/login") {
      document.body.scrollTop = 0
      window.scrollTo(0,0);
    }
  }


  render() {
    let {
      getPromotionalApiDataSuccess,
      getPromotionalApiDataError,
      getPromotionalApiDataLoader,
    } = this.props;

    let { selectedPriceTab, selectedSearchedCarTab } = this.state;
    let trustedCars = [];
    let mostSearched = [];
    let commercialVehicles = [];

    if (getPromotionalApiDataSuccess) {
      const {
        trusted_cars,
        most_searched,
        commercial_vehicles,
      } = getPromotionalApiDataSuccess;

      if (selectedPriceTab === 0) {
        trustedCars = trusted_cars.low_range;
      } else if (selectedPriceTab === 1) {
        trustedCars = trusted_cars.mid_range;
      } else if (selectedPriceTab === 2) {
        trustedCars = trusted_cars.upper_range;
      }

      if (selectedSearchedCarTab === 0) {
        mostSearched = most_searched.hatch_back;
      } else if (selectedSearchedCarTab === 1) {
        mostSearched = most_searched.sedan;
      } else if (selectedSearchedCarTab === 2) {
        mostSearched = most_searched.suv;
      }

      commercialVehicles = commercial_vehicles;

    }

    const staticAutoParts = [
      {remote_image: require('../assets/single-elements/car-parts/eukonline_service_kit.png'),
      title: "SERVICE KITS",
      price: 20,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-kits'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_tyres.jpg"),
      title: "TYRES",
      price: 25,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-tyres'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_engines.jpg"),
      title: "ENGINES",
      price: 150,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-engines'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_tail_light.jpg"),
      title: "AUTO BODY PARTS",
      price: 10,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-exterior'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_sensors.jpg"),
      title: "SENSORS",
      price: 15,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-sensors'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_entertainment_systems.jpg"),
      title: "ENTERTAINMENT",
      price: 80,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-ent'
}
    ]
      return (
        <div className="dealer">
          {/* Best Deals Start */}
          <div
            className="best-deal"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingTop: '60px',
              paddingBottom: '75px'
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Trending This Week</h2>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '0px'}}>
              <div className="row">
                {prodLandingData && prodLandingData.map((d, i) => {
                   return (
                     <LazyLoad height={20} offset={100} once >
                       <PromoElements d={d} i={i}>
                       </PromoElements>
                     </LazyLoad>
                   );
                 })}
              </div>
            </div>
          </div>

          {/* Banners */}
          {/* Banners */}
          {/* Recommended For You Ends */}


          {/* commercial vehicles */}
          {/*<LocalService />*/}


          {/* Auto Parts Start */}
          {/* Auto Parts ends */}
          {/* Research & Blog Start */}
          {/* Research & Blog */}
        </div>
      );
  }
}

export default withRouter(BestDeals);
