console.log(process.env.REACT_APP_API_ENDPOINT)

console.log("=====================================================")
let credentials = {
  API_URL: "http://localhost/api",
  BASE_URL: "http://localhost:8263",
  PROMOTIONAL_API_URL: "http://localhost/api/promotional/",
  PRODUCT_URL: "http://localhost/products",
  PRODUCT_DETAILS_URL: "http://localhost/api/products"
};

if(process.env.REACT_APP_API_ENDPOINT==="staging"){
  credentials = {
    API_URL: "http://35.177.122.101/api",
    BASE_URL: "https://cmct-fashion.co.uk",
    PROMOTIONAL_API_URL: "http://35.177.122.101/api/promotional/",
    PRODUCT_URL: "http://35.177.122.101/products",
    PRODUCT_DETAILS_URL: "http://35.177.122.101/api/products"
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="development"){
  credentials = {
    API_URL: "http://localhost:8263/api",
    BASE_URL: "http://localhost:8263",
    PROMOTIONAL_API_URL: "http://localhost:8263/api/promotional/",
    PRODUCT_URL: "http://localhost:8263/products",
    PRODUCT_DETAILS_URL: "http://localhost:8263/api/products"
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="prod"){
  credentials = {
    API_URL: "https://cmct-fashion.co.uk/api",
    BASE_URL: "https://cmct-fashion.co.uk",
    PROMOTIONAL_API_URL: "https://cmct-fashion.co.uk/api/promotional/",
    PRODUCT_URL: "https://cmct-fashion.co.uk/products",
    PRODUCT_DETAILS_URL: "https://cmct-fashion.co.uk/api/products"
  }
}

export default credentials;
