import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardImg, CardBody, CardSubtitle } from 'reactstrap';
import 'react-input-range/lib/css/index.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AddIcon from '@material-ui/icons/Add';
import { veh_data } from '../shared/vehicle_make_and_models';
import './ImgAndForm.css';
import Button from '@material-ui/core/Button';
import cmctModelWomen from '../assets/images/cmct/cat-women-b.jpg';
import cmctModelMen from '../assets/images/cmct/cmct-men-aaa.jpg';


class ImgAndForm extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();

    this.handleSearch = this.handleSearch.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNav = this.handleNav.bind(this)

    this.state = {
      value4: {
        min: 100,
        max: 5000
      },
      fromPrice: 0,
      toPrice: 75000,
      makeSelected: null,
      modelSelected: 'any',
      typeSelected: 'cars',
      message: '',
      carPrices: [
        0,
        100,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        22500,
        25000,
        27500,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        150000
      ]
    };
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  toggle(typeSelected) {
    if (this.state.typeSelected !== typeSelected) {
      this.setState({ typeSelected });
    }
  }

  handleNav (page) {
    const {history} = this.props

    if (page === "men"){
      history.push({
        pathname: "/search/cat-search-men",
      })
    }
    if (page === "women"){
      history.push({
        pathname: "/search/cat-search-women",
      })
    }

  }

  error(message) {
    this.setState({ message });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };
  handleMakeChange(event) {
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    this.setState({ modelSelected: event.target.value });
  }

  handleFromPriceChange = event => {
    this.setState({ fromPrice: event.target.value });
  };
  handleToPriceChange = event => {
    this.setState({ toPrice: event.target.value });
  };

  async handleSearch(event) {
    event.preventDefault();
    let {
      makeSelected,
      fromPrice,
      toPrice
    } = this.state;
    if (makeSelected === null) {
      this.error('Make is missing');
      this.searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return;
    }
    const query = `one-search/${makeSelected}/${fromPrice}/${toPrice}/1/b`;
    this.props.onSearch(query);
  }

  keyToOption(key) {
    return key
      .split('-')
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(' ');
  }

  render() {
    const vehicles = veh_data.reduce(
      (acc, veh, i) => {
        let make = Object.keys(veh)[0];
        let vehModels = veh[make];
        return {
          makes: [
            ...acc.makes,
            <option key={make + i} value={make}>
              {this.keyToOption(make)}
            </option>
          ],
          models: {
            ...acc.models,
            [make]: [
              <option key={make + i + 0} value="any">
                Any Model
              </option>,
              vehModels.map((model, i) => {
                return (
                  <option key={make + model + i} value={model}>
                    {this.keyToOption(model)}
                  </option>
                );
              })
            ]
          }
        };
      },
      { makes: [], models: [] }
    );

    const selectMinValues = this.state.carPrices
      .filter((val) => {
        return val < this.state.toPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.carPrices
      .filter((val) => {
        return val > this.state.fromPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });


    const selectedModels =
      this.state.makeSelected && this.state.makeSelected.length ? (
        vehicles.models[this.state.makeSelected]
      ) : (
        <option selected disabled>
          {/*Model (select make first)*/}
        </option>
      );
    return (
      <div
        className="col-lg-12"
        style={{ padding: '0px' }}
      >
        <header className="headerbg d-flex">
          <div
            className="col-sm-12 col-xs-12 offset-lg-0 col-lg-12 col-md-12"
            style={{ paddingLeft: '0px', paddingRight: '0px', position: 'relative' }}
          >
            <div className="container my-auto">
              <div className="bg-text">
                <div className="img-form-header"
                    style={{fontSize: '50px', fontWeight: 700, textAlign: 'center',
                      fontStyle: 'italic', borderRadius: '5px', backgroundColor: 'white',
                      textShadow: '0 1px, 1px 0, 1px 1px'}}>
                  25% OFF
                </div>
              </div>
            </div>
            <div className="container my-auto" style={{textAlign: 'center', position: 'absolute', bottom: '20px'}}>
              <Button variant="contained"
                      style={{color: 'white', backgroundColor: 'black'}}
                      disableElevation
                      href="/search/shop"
              >
                SHOP NOW
              </Button>
            </div>
          </div>
        </header>
        <div className="col-lg-10" />
        <div className="container" style={{ backgroundColor: 'white' }}>
          <div className="header-bottom ">
            <div className="row" style={{boxShadow: "4px 4px 1px #EEE", lineHeight: 1.25, paddingBottom: '75px'}}>
              <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    className="mx-auto car-img img-responsive home-categories"
                    src={cmctModelMen}
                    alt="Men"
                  />
                  <CardBody style={{ textAlign: 'center', paddingBottom: '15px', paddingTop: '25px'}}>
                    <div className="car-body-wrapper cards-body">
                      <CardSubtitle style={{ paddingBottom: '10px', fontSize: '13px'}}>
                        {/*eslint-disable-next-line*/}
                        <div className="home-categories-text">
                          MEN
                        </div>
                      </CardSubtitle>
                    </div>
                  </CardBody>
                  <div style={{textAlign: 'center'}}>
                    <Button variant="outlined"
                            style={{padding: '10px 15px 5px 15px', border: '2px solid black'}}
                            onClick={(event) =>
                              this.handleNav("men"
                              )}
                            size="sm">
                      {'SHOP MEN'}
                    </Button>
                  </div>
                </Card>

              </div>
              <div className="col-12 col-md-6 col-sm-6 col-lg-6 ">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    className="mx-auto car-img img-responsive home-categories"
                    // src="../assets/images/cmct/cmct-model-in-car.jpg"
                    src={cmctModelWomen}
                    alt="Women"
                  />
                  <CardBody style={{ textAlign: 'center', paddingBottom: '15px', paddingTop: '25px'}}>
                    <div className="car-body-wrapper cards-body">
                      <CardSubtitle style={{ paddingBottom: '10px', fontSize: '13px'}}>
                        {/*eslint-disable-next-line*/}
                        <div className="home-categories-text">
                          WOMEN
                        </div>{' '}
                      </CardSubtitle>
                    </div>
                  </CardBody>
                  <div style={{textAlign: 'center'}}>
                    <Button variant="outlined"
                            style={{padding: '10px 15px 5px 15px', border: '2px solid black'}}
                            onClick={(event) =>
                              this.handleNav("women"
                              )}
                            size="sm">
                      {'SHOP WOMEN'}
                    </Button>
                  </div>
                </Card>

              </div>
            </div>
            <div className="row"
                 style={{boxShadow: "4px 4px 1px #EEE", lineHeight: 1.25, paddingTop: '20px', minHeight: '150px',
                 backgroundColor: '#ffef36', color: 'black', alignItems: 'center', paddingBottom: '20px'}}>
              <div className="col-12 col-md-4 col-sm-12 col-lg-4" style={{paddingBottom: '20px'}}>
                <div style={{ fontWeight: 900, fontSize: '30px'}}>CMCT WORLD</div>
                <div style={{ fontWeight: 200, fontSize: '20px', letterSpacing: '19px'}}>PREMIER</div>
              </div>
              <div className="col-12 col-md-4 col-sm-12 col-lg-4">
                <div style={{fontWeight: 900, fontSize: '25px'}}>
                  <ListItem button style={{border: '2px solid black', marginTop: '5px'}}>
                    <ListItemIcon>
                      <FavoriteBorderIcon />
                    </ListItemIcon>
                    <ListItemText primary="CMCT DATING" />
                  </ListItem>
                </div>
                <div style={{fontWeight: 900, fontSize: '25px'}}>
                  <ListItem button style={{border: '2px solid black', marginTop: '5px'}}>
                    <ListItemIcon>
                      <MusicNoteIcon />
                    </ListItemIcon>
                    <ListItemText primary="CMCT RECORDS" />
                  </ListItem>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-12 col-lg-4">
                <div style={{fontWeight: 900, fontSize: '25px'}}>
                  <ListItem button style={{border: '2px solid black', marginTop: '5px'}}>
                    <ListItemIcon>
                      <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="CMCT FINANCE" />
                  </ListItem>
                </div>
                <div style={{fontWeight: 900, fontSize: '25px'}}>
                  <ListItem button style={{border: '2px solid black', marginTop: '5px'}}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="CMCT CHARITY" />
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ImgAndForm);
